import React,{Component} from 'react';
import LoadingBar from 'react-top-loading-bar';
import { isLogin, logout } from "../utils/index";
import PaginationComponent from "react-reactstrap-pagination";
import {db,fireauth} from '../services/firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
    Navbar,
    Table,
    NavbarBrand,
    Nav,
    Badge,
    Pagination, PaginationItem, PaginationLink,
    Input,
    InputGroup,
    InputGroupAddon,
    NavItem,
    NavLink,
    Row,Col,
    Button,
    NavbarText,
  } from 'reactstrap';
  import {Link, Redirect} from 'react-router-dom'
export default class Home extends Component {


    constructor(props)
    {
        super(props)

        this.state={
          isLogin: isLogin(),
          original_users:[],
            users:[],
            loaderHeight:3,
            modalVisible:false,
            users_count:0,
            user_address:'',
            user_name:'',
            registered_as : 0,
            user_id:'',
            email_address:'',
            searchText:'',
            coords:{},
            currentPage:1,
            itemsPerPage:10,
            last_item:{},
            first_item:{},
            data_index:0
          
        }
    }

    handleLogout = async () => {
      if (!isLogin) {
        this.setState({
          isLogin: false
        });
      }
      await fireauth.signOut(); 
      logout();
      this.props.history.push("/auth");
    };

    toggleModal = () => {
      this.setState({modalVisible: !this.state.modalVisible})
    }

    componentDidMount(){
      this.LoadingBar.continuousStart()      
      var query = db.collection('users').orderBy('created_at','desc').limit(10)       
      query.get().then(async (snapShot) => {
         var temp = []
         await snapShot.docs.forEach((item)=>{
                temp.push({item:item.data(),id:item.id})
            })
          this.setState({users:temp,original_users:temp,last_item:snapShot.docs[snapShot.docs.length-1],first_item:snapShot.docs[snapShot.docs.length-1]})
            this.LoadingBar.complete()

      this.getUsersCount();
    })}
    getUsersCount(){
      db.collection('users').orderBy('created_at','desc').get().then(async snapShot=>{
        this.setState({users_count: snapShot.docs.length })
       
       })
    }

   async fetchUsers(page){      
      const {currentPage, itemsPerPage,last_item,first_item,data_index} = this.state
      const str = page * itemsPerPage - itemsPerPage
    
      this.LoadingBar.continuousStart() 
      var query = ""
      if(page<currentPage){
      await  db.collection('users').orderBy('created_at','desc').get().then((snapShot)=>{
          var doc = snapShot.docs[str-1]
         if(doc!=undefined){
          query = db.collection('users').orderBy('created_at','desc').limit(itemsPerPage).startAt(doc) 

         }
         else {
          query = db.collection('users').orderBy('created_at','desc').limit(itemsPerPage).startAt(snapShot.docs[str]) 

         }
        })
        
     
     
      }
      else {


        if(Math.abs(str-data_index) >10){

          await  db.collection('users').orderBy('created_at','desc').get().then((snapShot)=>{
            var doc = snapShot.docs[str-2]
           
            query = db.collection('users').orderBy('created_at','desc').limit(itemsPerPage).startAt(doc) 
  
          
           
          })

        }else{
          query = db.collection('users').orderBy('created_at','desc').limit(itemsPerPage).startAt(last_item) 

        }



      }
      query.get().then(async (snapShot) => {
         var temp = []
         await snapShot.docs.forEach((item)=>{
                temp.push({item:item.data(),id:item.id})
            })
            this.setState({first_item:last_item,data_index:str})
            this.setState({users:temp,original_users:temp,last_item:snapShot.docs[snapShot.docs.length-1]})
            this.LoadingBar.complete()
        })
       

    }
     timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
     
        var time = date + ' ' + month + ' ' + year  ;
        return time;
      }
      
      viewDetails(id,index) {
      
var userData = this.state.users[index].item

this.setState({user_name:userData.first_name+' '+userData.last_name,
user_address:userData.address,
email_address:userData.email_address,
registered_as:userData.registered_as != null && userData.registered_as === 0?'Donor':'Need Help',
coords:{latitude:userData.latitude,longitude:userData.longitude},
user_id: userData.id
})


this.toggleModal()

      }

      showMap(lat,lng){
        var url = "https://maps.google.com/?q=" + lat + "," + lng;
        window.open(url);
     }  



     disableUser(uid,index,mode){

  var r = window.confirm("Are you sure about this action?");
  if (r === true) {

  this.LoadingBar.continuousStart()
  db.collection("users").doc(uid).update({
    status:mode,
  }).then(()=> {
    var users_temp = [...this.state.users]

    users_temp[index].item.status = mode;
    this.setState({users:users_temp})
    this.LoadingBar.complete()


   }).catch(function(error) {

    console.error("Error removing document: ", error);

 });
} 
     
     }

     searchUser(txt){
       this.setState({searchText:txt})

       var users = [...this.state.original_users]
if(txt.length>0){
  var temp = users.filter((item)=>(item.item.first_name.toLowerCase()).includes(this.state.searchText) )
  this.setState({users:temp})
}
else {
  this.setState({users:this.state.original_users})

}
      
     }

     signOut = async () =>{
      var r = window.confirm("Are you sure you want to logout?");
      if (r === true) {

        
        this.handleLogout()
   
      }
      
      
    

     }
     handleSelected=(selectedPage)=> {
      this.setState({ currentPage: selectedPage });
   this.fetchUsers(selectedPage)
    }
render(){

    var {users_count,searchText,users,modalVisible,user_address,user_name,email_address,registered_as,coords,user_id} = this.state;
    
    return(  <div>
{this.state.isLogin ? (
           
           <div style={{backgroundColor:'white',height:'100%'}}>
           <Modal isOpen={modalVisible} toggle={this.toggleModal}>
         <ModalHeader toggle={this.toggleModal}>{user_name}</ModalHeader>
         <ModalBody>
 
           <p> {user_address} <a onClick={()=>this.showMap(coords.latitude,coords.longitude)} href="#">
   
   <p>Open Map</p>
 
 </a> </p>
 
           <br />
           <Row>
         <Col><h5>Email</h5></Col>
 
 
     <Col><p>{email_address}</p></Col>
       </Row>
 
       <Row>
         <Col><h5>Registered as</h5></Col>
 
 
     <Col><p>{registered_as}</p></Col>
       </Row>        
          </ModalBody>
         <ModalFooter>
 
       
 
     <Link to={'/user-post/'+user_id+'/'+user_name}>
 
           <Button color="primary" onClick={this.toggleModal}>View Posts</Button>{' '}
           </Link>
           <Button color="secondary" onClick={this.toggleModal}>Close</Button>
         </ModalFooter>
       </Modal>
          
       
          <LoadingBar
           height={3}
           color='#1e824c'
           onRef={ref => (this.LoadingBar = ref)}
         />
          
       <Navbar color="light" light expand="md">
         <NavbarBrand href="/"><h3>Donate Dashboard</h3></NavbarBrand>
      
         <Nav className="mr-auto" navbar>
             <NavItem>
             <Link to={'/feedback'}> <NavLink href="/feedback">Feedback</NavLink></Link>
             </NavItem>
 
             <NavItem>
             <Link to={'/reported'}> <NavLink href="/components">Reported Posts  </NavLink></Link>
             </NavItem>
           
             <NavItem>
             <Link to={'/under-review'}> <NavLink href="/under-review">Under Review  </NavLink></Link>
             </NavItem>
 



             {/* <NavItem>
             <Link to={'/volunteer-requests'}> <NavLink href="/volunteer-requests">Volunteer Requests </NavLink></Link>
             </NavItem>
           */}
 
 
             </Nav>
           <Nav className="mr-auto" navbar>
             <NavItem>
             <InputGroup>
         <Input value={searchText} onChange={(e)=>this.searchUser(e.target.value)} />
         <InputGroupAddon addonType="prepend"><Button color='info' onClick={()=>this.searchUser(searchText)}>Search</Button></InputGroupAddon>
 
       </InputGroup>
             </NavItem>
         
                      
          </Nav>
 
       
         
          <Nav className="ml-auto" navbar>
           <NavItem >
 
 <Button color='warning' onClick={this.signOut}>Sign Out</Button>
 
 </NavItem>
 
 </Nav>
       </Navbar>
 
       <div style={{backgroundColor:'#fefefe',padding:20}}>
 
       
       <div style={{paddingLeft:50,paddingRight:50}}>
       <div style={{paddingTop:10,paddingBottom:10,display:'flex',justifyContent:'space-between'}} >
         <h4>Users</h4>

         {users_count>0 && <h4> Total users: {users_count}</h4> }

       </div>
       <Table  >
       <thead>
         <tr>
           <th>#</th>
           <th>Name</th>
           <th>Signed up</th>
           <th>Provider</th>
           {/* <th>Contact</th> */}
           <th>Status</th>
 
           
           <th></th>
 
           <th></th>
         </tr>
       </thead>
       <tbody>
 
           {users.map(((item,index)=>{
            return <tr key={index}>
           <th scope="row">{index+1}</th>
           <td>{item.item.first_name+ ' '+item.item.last_name}</td>
           {/* <td>{item.item.email_address}</td> */}
 
           {/* <td>{item.item.country_code+'-'+item.item.phone}</td> */}
 
           <td>{this.timeConverter(item.item.created_at)}</td>


           <td style={{textTransform:'capitalize'}}>{item.item.auth_provider!=''?item.item.auth_provider:'Email address'}</td>
 
 
           <td>{item.item.status === 1? 'Active':'Disabled'}</td>
        
 <td>
 <Button color="info" onClick={()=>this.viewDetails(item.id,index)}>View</Button>{' '}
 
 </td>
 
 
 <td>
 
 
   {item.item.status === 1? <Button color="danger" onClick={()=>this.disableUser(item.id,index,0)}>Disable</Button>: <Button color="primary" onClick={()=>this.disableUser(item.id,index,1)}>Enable</Button> }
 </td>
         </tr>
           }))}
         
        
       </tbody>
     </Table>
     <PaginationComponent
          totalItems={users_count}
          pageSize={10}
          onSelect={this.handleSelected}
        />

 
 
       </div>
       
 
 
 
 
 
           </div>
     
       </div>
       ) : (
        this.props.history.push("/")
      )}

      </div>


   )
}



}



