import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db} from '../services/firebase';
import {GeoFirestore} from 'geofirestore';
import "../styles/react_dates_overrides.css";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'
import 'react-dates/initialize';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import {sendPushNotification } from '../utils/NetworkFunctions'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Navbar,
    Nav,
    NavbarBrand,
    Card, CardText, CardBody,
    Button,
  
  
  } from 'reactstrap';
  

  export default class VolunteerRequests extends React.Component {
   

    constructor(props){
        super(props)

        this.state = {
            v_requests :[],
            dropdownOpen:false,
            focused:false,
        }    
    }

       
   


      


      handleChange = (val)=> {
    var posts = [...this.state.v_requests]

   if(posts.length>0){
      if(val.target.value == 1){
       var sorted =  posts.sort((a, b) => (b.data.status) - (a.data.status));
       this.setState({all_posts:sorted})
      }

      else    if(val.target.value == 2){
       var sorted =  posts.sort((a, b) => (a.data.status) - (b.data.status));
       this.setState({all_posts:sorted})
      }

      else  if(val.target.value == 3){
       var sorted =  posts.sort((a, b) => (b.data.created_at) - (a.data.created_at));
       this.setState({all_posts:sorted})
      }
   }
   
 

}

toggle = ()=>{
    this.setState({dropdownOpen:!this.state.dropdownOpen})
}




      render(){
          var {v_requests,dropdownOpen} = this.state
          return (
            <div style={{backgroundColor:'white',height:'100%',boxSizing:'border-box'}}>

            <LoadingBar
                height={3}
                color='#1e824c'
                onRef={ref => (this.LoadingBar = ref)}
              />
              <ToastContainer />
      
            <Navbar color="light" light expand="md">
        
              <NavbarBrand><h3 style={{color:'#2e3131'}} >Volunteer Donation Requests</h3><Nav>
        
          </Nav></NavbarBrand>
              <Nav>
              <Dropdown  isOpen={dropdownOpen} toggle={this.toggle}>
              <DropdownToggle color={'success'}  caret>
              Filter Posts
              </DropdownToggle>
            <DropdownMenu>
             
              <DropdownItem name="1" value="1" onClick={this.handleChange} >Active</DropdownItem>
          <DropdownItem name="2" value="2" onClick={this.handleChange} >Inactive</DropdownItem>
          <DropdownItem name="3" value="3" onClick={this.handleChange} >Created at</DropdownItem>
      
            </DropdownMenu>
          </Dropdown>
      
                  </Nav>
      
       
               <Nav>
      
               
               {/* <SingleDatePicker
        date={this.state.date} // momentPropTypes.momentObj or null
        numberOfMonths={1}
        isDayHighlighted={day => day.isSame( start_date, 'day' )}
            style={{height:40}}
      
        isOutsideRange={() => false}
        onDateChange={date => this.setState({date})} // PropTypes.func.isRequired
        focused={this.state.focused} // PropTypes.bool
        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
        id="your_unique_id" // PropTypes.string.isRequired,
      /> */}
               </Nav>
      
               
                {/* <NavbarText>Simple Text</NavbarText> */}
               
            </Navbar>


      </div>      



          )
      }











  }