import React,{Component} from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db,auth,fireauth} from '../services/firebase';
import { login } from "../utils";

import '../styles/LoginForm.css'
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
      Navbar,
      Table,
      NavbarBrand,
      Nav,
  
     Form,FormGroup,FormFeedback,Input,Label,
     
      Row,Col,
      Button,
    } from 'reactstrap';
    import {Link,Redirect,withRouter ,useHistory} from 'react-router-dom'


   class Login extends React.Component{


        constructor(props){
            super(props)
            this.state={
                username:'',
                password:'',
                username_valid:true,

                password_valid:true,
                is_authenticated:false,
            }
        }

        componentDidMount(){
          
          
        }
     

        processSignIn = async () =>{
        const {location} = this.props;
       
     
            //this.setState({is_authenticated:true})
           
            login();
            this.props.history.push('/dashboard')


           // window.location.reload()
        }


       verifyUser = (email) => {
        var usersRef = db.collection('dashboard_admins').where('email','==',email);

        usersRef.get().then(snapShot=>{
                if(snapShot && snapShot.docs.length>0){
                    this.LoadingBar.complete()
                      this.processSignIn()         
                }

                else {
                    this.LoadingBar.complete()                   
                    window.alert('Error, you are not an authorized person...')
                }
            }).catch(err=>{


                console.log(err)


            })
       }

        processLogin(event){
            event.preventDefault(); 
            var email = document.getElementById("email_input");
            var password = document.getElementById("password_input");

            this.LoadingBar.continuousStart()
            
            
            fireauth
            .signInWithEmailAndPassword(email.value,password.value)
            .then(data => {
                console.log(data.user.uid)
                 
               this.verifyUser(email.value)        
            }).catch(err=>{
               this.LoadingBar.complete()
                email.value = ''
                password.val = ''
                window.alert(err.message)
            })
                return;


            // if(username.length<3){
            //     window.alert('Please enter a valid username')
              
            //     return
            // }

            // if(password.length<2){
            //     window.alert('Please enter a valid password')
            //     return;
            // }

            // this.LoadingBar.continuousStart()
            // var usersRef = db.collection('dashboard_admins').where('username','==',username);

            // usersRef.get().then(snapShot=>{
            //     if(snapShot && snapShot.docs.length>0){
            //         if(snapShot.docs[0].data().password === password){
            //             this.LoadingBar.complete()
            //           this.processSignIn()                                                     
            //         }
            //         else {
            //             this.LoadingBar.complete()

            //             this.setState({username:'',password:''})
            //             window.alert('Error, wrong credentials...')
            //         }
            //     }

            //     else {
            //         this.LoadingBar.complete()

            //         this.setState({username:'',password:''})
            //         window.alert('Error, wrong credentials...')
            //     }
            // }).catch(err=>{


            //     console.log(err)


            // })



        }
       

        socialAuth(){
            var provider = new auth.GoogleAuthProvider();
            fireauth.signInWithPopup(provider).then((result)=> {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // The signed-in user info.
                var user = result.user;

               
                this.verifyUser(user.email) 
                // ...
              }).catch(function(error) {
                  console.log(error)
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
              });

        }
        render(){
    return  ( <div style={{backgroundColor:'#eee',height:'100vh'}}>
        <LoadingBar
          height={3}
          color='#1e824c'
          onRef={ref => (this.LoadingBar = ref)}
        />
               <div class="form-modal">
    
    <div class="form-toggle">
        <button id="login-toggle" onclick="toggleLogin()">Mumara Donate Dashboard</button>
       
    </div>

    <div id="login-form">
        <form onSubmit={(event)=>this.processLogin(event)}  >
            <input type="text" required id="email_input" placeholder="Enter email"/>
            <input type="password" required id="password_input"  placeholder="Enter password"/>
            <button type="submit"   class="btn login">login</button>
            
            <hr/>
            <button type="button" onClick={()=>this.socialAuth()} class="btn -box-sd-effect"> <i class="fa fa-google fa-lg" aria-hidden="true"></i> sign in with google</button>
        </form>
    </div>

  

</div>

</div>)

          }
        }                              
        


    export default withRouter(Login)