import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db} from '../services/firebase';
import {GeoFirestore} from 'geofirestore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Navbar,    
    Nav,
    NavbarBrand,Row,
    Card, CardText, CardBody,
   Button,
   Col,
   CardSubtitle,
   CardTitle,
  } from 'reactstrap';
  
  var geocollection = db.collection('reported_posts')
  const geofirestore = new GeoFirestore(db);
  const post_collection = geofirestore.collection('posts');
class Reported extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            dropdownOpen:false,
            all_posts:[],
name:'',
id:''
        }
    }

    timeConverter(UNIX_timestamp,mode){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
     if(mode === 1){
return  date + ' ' + month + ' ' + year
     }
        var time = date + ' ' + month + ' ' + year+'  '+hour+':'+min  ;
        return time;
      }
     componentDidMount(){    
         this.fetchPosts()       
     }


     fetchPosts(){
        this.LoadingBar.continuousStart()
     
    geocollection
      .get()
      .then(async snapshot => {
    var dataArray = [];
      await  snapshot.docs.forEach( async item => {
        if(item.data().resolved === undefined || item.data().resolved === null || item.data().resolved === false){
            
            var post_id = item.id;
            dataArray.push({data: item.data(), id: post_id});
        }      
        });

        this.fetchPostData(dataArray)

      });
     }



     async fetchPostData(arr) {
        var userRef = db.collection('users');
       
        const promises = [];
    
        arr.forEach(item => {
          let promise = userRef
            .where('id', '==', item.data.reported_by)
            .get()
            .then(async usersnap => {              
                item.data.name =
                (await usersnap.docs[0].data().first_name) +
                ' ' +
                usersnap.docs[0].data().last_name;
                item.data.phone =
                (await usersnap.docs[0].data().country_code) +
                usersnap.docs[0].data().phone;
            });
          
     
            let promise1 = post_collection.doc(item.data.post_id).get().then( snapShot=>{
             
              
     if (snapShot.data() != undefined) { 

        item.post_data =  {...snapShot.data(),id:item.data.post_id}
                }
                
            }).catch(err=>{
                console.log(err)
            })

            promises.push(promise);
           
            promises.push(promise1)
         
        });
       
    
        Promise.all(promises).then(async () => {
    

         this.setState({all_posts: arr})

          this.LoadingBar.complete()
        });
      }
     changeStatus(id,mode,index){
     
        switch (mode) {
            case 0:
                this.changePostStatus(id)
                break;
        

                case 1:
                this.deletePost(id,index)
                break;


            default:
                break;
        }


         
     }


 deletePost(id,index){

var r = window.confirm("Are you sure about this action?");
if (r === true) {

    
    var filtered = [...this.state.all_posts]

    var posts = filtered.filter(item=>item.post_data != undefined && item.post_data!= null)

   
    this.LoadingBar.continuousStart()
    db.collection("posts").doc(id).delete().then(async ()=> {

      await  posts.splice(index,1)
      this.setState({all_posts:posts})
      this.LoadingBar.complete()
      this.showConfirmation('Post removed successfully!',1)
      
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
} else {
    console.log('not delete')
}
     }

     showConfirmation(message,type){
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            type: type==2?'info':'success',
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
     }

 changePostStatus(id,status,index){

        this.LoadingBar.continuousStart()
        post_collection
        .doc(id)
        .update({
          status: status === 0 ? 1 : 0,
        })
        .then(data => {
         
          this.LoadingBar.complete()

var posts = [...this.state.all_posts]
posts[index].post_data.status = status === 0 ? 1 : 0;
          this.setState({all_posts:posts})
        })
        .catch(err => {
            this.LoadingBar.complete()
      
        });
     }

     changePostType(id,item_type,index){
       
         this.LoadingBar.continuousStart()
         post_collection
         .doc(id)
         .update({
           type: item_type === 0 ? 1 : 0,
         })
         .then(data => {
          
           this.LoadingBar.complete()
 
 var posts = [...this.state.all_posts]
 posts[index].post_data.type = item_type === 0 ? 1 : 0;
           this.setState({all_posts:posts})
         })
         .catch(err => {
             this.LoadingBar.complete()
       
         });
     }
     handleChange = (val)=> {

       
         var filtered = [...this.state.all_posts]

     var posts = filtered.filter(item=>item.post_data != undefined && item.post_data!= null)

    

        if(posts.length > 0){
          
            switch (Number(val.target.value)) {
                case 1:
                    var sorted =  posts.sort((a, b) =>  (b.post_data.status) - (a.post_data.status));
                    this.setState({all_posts:sorted})
                    break;


                case 2:
                    var sorted =  posts.sort((a, b) => (a.post_data.status) - (b.post_data.status));
                    this.setState({all_posts:sorted})
                    break;
                
                case 3:           
                    var sorted =  posts.sort((a, b) => (b.data.created_at) - (a.data.created_at));
        
                    this.setState({all_posts:sorted})

                break;

            
                default:
                    break;
            }

        }
    }

    markResolved(id,index){

     
        var filtered = [...this.state.all_posts]

        var posts = filtered.filter(item=>item.post_data != undefined && item.post_data!= null)

        this.LoadingBar.continuousStart()
        geocollection.doc(id).set({resolved:true},{merge:true}).then(async data=>{
                       
          posts.splice(index,1)
         
           this.setState({all_posts:posts})
           this.LoadingBar.complete()
           this.showConfirmation('Post has be marked as resolved',2)
           
            
        }).catch(err=>{

        })
    }
     toggle = ()=>{
         this.setState({dropdownOpen:!this.state.dropdownOpen})
     }
    render() {

        var {name,all_posts,dropdownOpen,} = this.state




        return (  <div style={{backgroundColor:'white',height:'100%'}}>

      <LoadingBar
          height={3}
          color='#1e824c'
          onRef={ref => (this.LoadingBar = ref)}
        />  <ToastContainer />

      <Navbar color="light" light expand="md">



        <NavbarBrand><h3 style={{color:'#2e3131'}} >Reported Posts </h3></NavbarBrand>
       
       
       
        <Nav>
        <Dropdown  isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color={'success'}  caret>
        Filter 
        </DropdownToggle>
      <DropdownMenu>
       
        <DropdownItem name="1" value="1" onClick={this.handleChange} >Active</DropdownItem>
    <DropdownItem name="2" value="2" onClick={this.handleChange} >Inactive</DropdownItem>
    <DropdownItem name="3" value="3" onClick={this.handleChange} >Created at</DropdownItem>

      </DropdownMenu>
    </Dropdown>

            </Nav>
    
         
          {/* <NavbarText>Simple Text</NavbarText> */}
      
      </Navbar>

      <div style={{backgroundColor:'#fefefe',padding:20}}>

      
            {all_posts.length > 0 && 
            all_posts.map((item,index)=>{
if(item.post_data !== null && item.post_data !==  undefined){
    return <Card style={{marginTop:20}}>
               
    <CardBody>
    <div style={{display:'flex',}}>

<div style={{flex:0.4}}>

        <h4 style={{color:"#2e3131",fontFamily:'Calibri'}}>Details</h4>

<CardTitle>
   {item.data.details}
</CardTitle>
    

    <CardSubtitle style={{color:"##474a4a"}}>{item.data.name}</CardSubtitle>
<CardSubtitle style={{color:"#474a4a"}}> {item.data.contact},  {item.data.phone}</CardSubtitle>
<CardSubtitle style={{color:"#474a4a"}}> { this.timeConverter(item.data.created_at,1)}</CardSubtitle>

<Button  color="info" style={{marginTop:13}} onClick={()=>this.markResolved(item.id,index)}>Mark Resolved</Button>
       
</div>
        <div style={{flex:1,flexDirection:'column'}}>

        <div>
    <h6 style={{color: item.post_data.status === 1?"#00b16a":"#cf000f",marginTop:10}}>
        {item.post_data.status === 0?'Inactive':'Active'}
            </h6>

      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

      <h5 style={{color:"#013243"}}>{item.post_data.type === 0?'Donating':'Requesting'}{' '} {item.post_data.category=='Cash'?'amount '+item.post_data.amount:item.post_data.value} </h5>
     
          </div> 

    </div>
       
       
     <p style={{color:"#6c7a89"}}>({item.data.poster_name})  {this.timeConverter(item.post_data.created_at)}    • in {item.post_data.category} - {item.post_data.sub_category}</p>
       
<h6 style={{color:"#2e3131",marginTop:10}}>Description</h6>



<CardText>{item.post_data.description}</CardText> 



{item.post_data.type === 0  && <div>
    <h6 style={{color:"#2e3131",marginTop:10}}>Points</h6>

<CardText>{item.post_data.points != undefined ? item.post_data.points:0}</CardText>                     

    </div>}    

<h6 style={{color:"#2e3131",marginTop:10}}>Address</h6>
<CardText>{item.post_data.address}</CardText>
 
<Button  color="primary" onClick={()=>this.changePostStatus(item.post_data.id,item.post_data.status,index)}>Change Status</Button>

   
<Button  color="warning" style={{marginLeft:10}}  onClick={()=>this.changePostType(item.post_data.id,item.post_data.type,index)}>Change Type</Button>

    <Button outline color="danger" style={{marginLeft:10}} onClick={()=>this.changeStatus(item.post_data.id,1,index)} >Delete </Button>
    

        </div>
    </div>



    </CardBody>
  </Card>
}
       
            })
            
            }





    </div>

    </div>

    



        );
    }
}

export default Reported;
