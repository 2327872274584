import React, { Component } from "react";
import { logout, isLogin } from "../utils";
import Home from '../components/Home'
import Login from '../components/Login'
import { Link,Redirect } from "react-router-dom";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: isLogin()
    };
  }

  handleLogout = () => {
    logout();
    this.setState({
      isLogin: false
    });
  };

  render() {

    if(this.state.isLogin){
      return <Home />
    }
    else {
      return <Login />
    }
    return ( <div>


          {this.state.isLogin ?
          
            <Home /> : <Login />

        }
        {/* <h1>Landing</h1>

        {this.state.isLogin ? (
          <button onClick={() => this.handleLogout()}>
            Click here to log out
          </button>
        ) : (
          <Link to="/auth">Go to sign in page</Link>
        )}
        <br />
        <br />
        <Link to="/dashboard">dashboard</Link> */}
      </div>
    );
  }
}

export default Landing;