import React,{Component} from 'react';
import "../styles/404.css"
export default class NotFound extends React.Component {


    render(){
        return(
   
            <div id="main">
    	<div class="fof">
        		<h1>Error 404</h1>
    	</div>
</div>
        )
    }
}