import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db} from '../services/firebase';
import {GeoFirestore} from 'geofirestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Navbar, 
    Nav,
    NavbarBrand,
    Card, CardText, CardBody,
   Button,
  
  
  } from 'reactstrap';
  

  const geofirestore = new GeoFirestore(db);
const geocollection = geofirestore.collection('posts');
class UserPosts extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            dropdownOpen:false,
            all_posts:[],
            name:'',
            id:''
        }
    }

    timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
     
        var time = date + ' ' + month + ' ' + year+'  '+hour+':'+min  ;
        return time;
      }
     componentDidMount(){    

var {name,id} = this.props.match.params

        this.setState({name,id})
        if(id!= undefined && id != null){
            this.fetchPosts(id)
        }
     }


     fetchPosts(id){
        this.LoadingBar.continuousStart()

         geocollection
      .where('poster', '==', id)
      .get()
      .then(snapshot => {
        var dataArray = [];
        snapshot.docs.forEach(async item => {
          var post_id = item.id;
          await dataArray.push({data: item.data(), id: post_id});
        });

        this.setState({all_posts: dataArray});

      this.LoadingBar.complete()
      });
     }
     changeStatus(id,mode,index){

        switch (mode) {
            case 0:
                this.changePostStatus(id)
                break;
        

                case 1:
                this.deletePost(id,index)
                break;


            default:
                break;
        }


         
     }

showConfirmation(){
  toast.success('Post deleted successfully!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    type:'success',
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

}
 deletePost(id,index){



var r = window.confirm("Are you sure about this action?");
if (r == true) {
  var posts = [...this.state.all_posts]
  this.LoadingBar.continuousStart()
    db.collection("posts").doc(id).delete().then(()=> {
      this.LoadingBar.complete()
         posts.splice(index,1)
         this.setState({all_posts:posts})
       this.showConfirmation()
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
} else {
    console.log('not delete')
}
     }

     changePostStatus(id,status,index){

          
        this.LoadingBar.continuousStart()
        geocollection
        .doc(id)
        .update({
          status: status == 0 ? 1 : 0,
        })
        .then(data => {
         
          this.LoadingBar.complete()

var posts = [...this.state.all_posts]
posts[index].data.status = status == 0 ? 1 : 0;
          this.setState({all_posts:posts})
        })
        .catch(err => {
            this.LoadingBar.complete()
      
        });
     }

     changePostType(id,item_type,index){
       

         this.LoadingBar.continuousStart()
         geocollection
         .doc(id)
         .update({
           type: item_type == 0 ? 1 : 0,
         })
         .then(data => {
          
           this.LoadingBar.complete()
 
 var posts = [...this.state.all_posts]
 posts[index].data.type = item_type == 0 ? 1 : 0;
           this.setState({all_posts:posts})
         })
         .catch(err => {
             this.LoadingBar.complete()
       
         });
     }
     handleChange = (val)=> {
         var posts = [...this.state.all_posts]
     
        if(posts.length>0){
           if(val.target.value == 1){
            var sorted =  posts.sort((a, b) => (b.data.status) - (a.data.status));
            this.setState({all_posts:sorted})
           }

           else    if(val.target.value == 2){
            var sorted =  posts.sort((a, b) => (a.data.status) - (b.data.status));
            this.setState({all_posts:sorted})
           }

           else  if(val.target.value == 3){
            var sorted =  posts.sort((a, b) => (a.data.created_at) - (b.data.created_at));
            this.setState({all_posts:sorted})
           }
        }        
      
    
    }

     toggle = ()=>{
         this.setState({dropdownOpen:!this.state.dropdownOpen})
     }
    render() {

        var {name,all_posts,dropdownOpen,} = this.state




        return (  <div style={{backgroundColor:'white',height:'100%'}}>

      <LoadingBar
          height={3}
          color='#1e824c'
          onRef={ref => (this.LoadingBar = ref)}
        />
            <ToastContainer />

      <Navbar color="light" light expand="md">
        <NavbarBrand><h3 style={{color:'#2e3131'}} > {name}'s Posts</h3></NavbarBrand>
        <Nav>
        <Dropdown  isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color={'success'}  caret>
        Filter Posts
        </DropdownToggle>
      <DropdownMenu>
       
        <DropdownItem name="1" value="1" onClick={this.handleChange} >Active</DropdownItem>
    <DropdownItem name="2" value="2" onClick={this.handleChange} >Inactive</DropdownItem>
    <DropdownItem name="3" value="3" onClick={this.handleChange} >Created at</DropdownItem>

      </DropdownMenu>
    </Dropdown>

            </Nav>
    
         
          {/* <NavbarText>Simple Text</NavbarText> */}
      
      </Navbar>

      <div style={{backgroundColor:'#fefefe',padding:20}}>

      
            {all_posts.length > 0 && 
            all_posts.map((item,index)=>{

            return <Card>
               
                <CardBody>
                   
                <div>
                <h6 style={{color: item.data.status === 1?"#00b16a":"#cf000f",marginTop:10}}>
                    {item.data.status === 0?'Inactive':'Active'}
                        </h6>
                   
                    <h5 style={{color:"#013243"}}>{item.data.type == 0?'Donating':'Requesting'}{' '} {item.data.category=='Cash'?'amount '+item.data.amount:item.data.value} </h5>
           
                </div>
                   
                   
                 <p style={{color:"#6c7a89"}}> {this.timeConverter(item.data.created_at)}    • in {item.data.category} - {item.data.sub_category}</p>
                   
           <h6 style={{color:"#2e3131",marginTop:10}}>Description</h6>
           
           <CardText>{item.data.description}</CardText> 




            {item.data.type == 0  && <div>
                <h6 style={{color:"#2e3131",marginTop:10}}>Points</h6>
           
           <CardText>{item.data.points != undefined ? item.data.points:0}</CardText>                     
       
                </div>}    
           
           <h6 style={{color:"#2e3131",marginTop:10}}>Address</h6>
        <CardText>{item.data.address}</CardText>
             

                 
            


              
        <Button  color="primary" onClick={()=>this.changePostStatus(item.id,item.data.status,index)}>Change Status</Button>

                <Button  color="info" style={{marginLeft:30}} >Edit Post</Button>
            <Button  color="warning" style={{marginLeft:10}}  onClick={()=>this.changePostType(item.id,item.data.type,index)}>Change Type</Button>

                <Button outline color="danger" style={{marginLeft:10}} onClick={()=>this.changeStatus(item.id,1,index)} >Delete </Button>
                
                </CardBody>
              </Card>
            })
            
            }





    </div>

    </div>

    



        );
    }
}

export default UserPosts;
