import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect
} from "react-router-dom";


import "./styles/react_dates_overrides.css";

import './App.css';
import Home from './components/Home'
import About from './components/About'
import UserPosts from './components/UserPosts';
import Login from './components/Login';
import Managers from './components/Managers';
import UnderReview from './components/UnderReview';
import Reported from './components/Reported';
import PrivateRoute from './components/PrivateRoute'
import PublicRoute from './components/PublicRoute'
import Landing from './components/Landing';
import NotFound from './components/NotFound';
import FeedbackPosts from './components/FeedbackPosts';
import VolunteerRequests from './components/VolunteerRequests';



function App(){
  return (
<Router>
          <Switch>
          <PublicRoute restricted={false} component={Landing} path="/" exact />
          <PublicRoute restricted={false} component={Login} path="/auth" exact />
          <PrivateRoute component={UserPosts} path="/user-post/:id/:name" exact />
          <PrivateRoute component={Reported} path="/reported" exact />
          <PrivateRoute component={FeedbackPosts} path="/feedback" exact />
          <PrivateRoute component={UnderReview} path="/under-review" exact />
          <PrivateRoute component={Home} path="/dashboard" exact />
          <PrivateRoute component={VolunteerRequests} path="/volunteer-requests" exact/>
          <Route path="*" component={NotFound} />
          
          
          </Switch>
        </Router>
  );
}

export default App;






