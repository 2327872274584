import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db} from '../services/firebase';
import {GeoFirestore} from 'geofirestore';
import "../styles/react_dates_overrides.css";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'
import 'react-dates/initialize';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import {sendPushNotification } from '../utils/NetworkFunctions'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Navbar,
    
    Nav,
    NavbarBrand,
    Card, CardText, CardBody,
   Button,
  
  
  } from 'reactstrap';
  
class FeedbackPosts extends Component {
    constructor(props){
        super(props)
        this.state= {
            dropdownOpen:false,
            all_feedbacks:[],
            name:'',
            id:''
        }
    }
    componentDidMount(){           
        this.fetchFeedbacks()
    }
    timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
     var meridium = hour>12 ? 'PM' : 'AM'
        var time = date + ' ' + month + ' ' + year+'  '+hour+':'+min+ meridium  ;
        return time;
      }
    fetchFeedbacks(){
        this.LoadingBar.continuousStart()

       db.collection('feedback')
        .orderBy('created_at','desc')
        .get()
        .then(snapshot => {
          var dataArray = [];
          snapshot.docs.forEach(async item => {
            var post_id = item.id;
            await dataArray.push({data: item.data(), id: post_id});
          });
  
          this.fetchPostUsers(dataArray)
  
         
    })

    }

    async fetchPostUsers(posts){
        if(posts.length>0){
       var promises = []     
         posts.forEach( async (item,index)=>{
            
           var promise = db.collection('users').where('id','==',item.data.user).get().then(async snapShot=>{
              item.user = snapShot.docs[0].data()
     
           }).catch(err=>{
             console.log(err)
           })
           
           promises.push(promise)
         })
     
     
     
       Promise.all(promises).then(async ()=>{
        this.setState({all_feedbacks:posts})
         this.LoadingBar.complete()
       }).catch(err=>{
         console.log(err)
       })   
        }  
      }
      deletePost(id,index){

        var r = window.confirm("Are you sure about this action?");
        if (r == true) {
          var posts = [...this.state.all_feedbacks]
        db.collection("feedback").doc(id).delete().then(()=> {
          posts.splice(index,1)
          this.setState({all_feedbacks:posts})
          toast.success('Feedback deleted successfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            type:'success',
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
           
        }).catch(function(error) {
            console.error("Error removing document: ", error);
        });
        }
         }
      changeStatus(id,mode,index){

        this.deletePost(id,index)
         
     }
    handleChange = (val)=> {
        var posts = [...this.state.all_feedbacks]
    
       if(posts.length>0){
          if(val.target.value == 1){
           var sorted =  posts.sort((a, b) => (b.data.status) - (a.data.status));
           this.setState({all_feedbacks:sorted})
          }

          else    if(val.target.value == 2){
           var sorted =  posts.sort((a, b) => (a.data.status) - (b.data.status));
           this.setState({all_feedbacks:sorted})
          }

          else  if(val.target.value == 3){
           var sorted =  posts.sort((a, b) => (a.data.created_at) - (b.data.created_at));
           this.setState({all_feedbacks:sorted})
          }
       }        
     
   
   }

    toggle = ()=>{
        this.setState({dropdownOpen:!this.state.dropdownOpen})
    }

    render() {
        var {name,all_feedbacks,dropdownOpen,} = this.state
        return (
            <div style={{backgroundColor:'white',height:'100%'}}>
            <LoadingBar
          height={3}
          color='#1e824c'
          onRef={ref => (this.LoadingBar = ref)}
        />
            <ToastContainer />

      <Navbar color="light" light expand="md">
        <NavbarBrand><h3 style={{color:'#2e3131'}} > Users Feedback</h3></NavbarBrand>
        <Nav>
       

            </Nav>
 
      </Navbar>
 
      {all_feedbacks.length > 0 && 
all_feedbacks.map((item,index)=>{

return <Card key={index} style={{marginTop:10,marginLeft:12,marginRight:12}}>
   
    <CardBody>
       
    <div>
    <h5  style={{color:"#2e3131",marginTop:10}}>{item.user.first_name+' '+item.user.last_name}</h5>
    </div>
       
       
     <p style={{color:"#6c7a89"}}> {this.timeConverter(item.data.created_at)}    •  {item.data.contact}</p>
       

{/* <h3>{item.user.first_name +' '+item.user.last_name}</h3> */}
<CardText >{item.data.message}</CardText> 

<CardText>  Joined on {this.timeConverter(item.user.created_at)}</CardText>                     
<h6 style={{color:"#2e3131",marginTop:10,fontWeight:'bold'}}>Address</h6>
<CardText>{item.user.address}</CardText> 
    <a style={{textDecoration:'none',display:'block'}} href="javascript:;" onClick={()=>this.changeStatus(item.id,1,index)} >  
    
    <h6 style={{color:"#f03434",marginTop:10,fontWeight:'bold'}}>Delete this post</h6>
    </a>
    </CardBody>
  </Card>
})

}
 
      </div>
    
        );
    }
}

export default FeedbackPosts;
