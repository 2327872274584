import React,{Component} from 'react';



export default class About extends Component {


    constructor(props)
    {
        super(props)

        this.state={

            data:[{name:'Esperanza'},{name:'Michele'},{name:'Audrey'},{name:'Olin'},{name:'Makayla'},{name:'Cielo'},{name:'Eriberto'},{name:'Doug'},]
        }
    }

    render(){
        return <div>

            <h2>About</h2>
        </div>


    }







}