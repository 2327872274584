import React,{Component} from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db,auth} from '../services/firebase';
import {
    Modal, ModalHeader, ModalBody, ModalFooter,
      Navbar,
      Table,
      NavbarBrand,
      Nav,
      Input,
      InputGroup,
      InputGroupAddon,
      NavItem,
     
      Row,Col,
      Button,
    } from 'reactstrap';
    import {Link} from 'react-router-dom'


    export default class Managers extends React.Component{


        constructor(props){
            super(props)
            this.state={

            }
        }

        render(){
            return <div>


                <h2>Managers</h2>
            </div>
        }



    }
