import firebase from 'firebase';


const firebaseConfig = {
    apiKey: "AIzaSyDBzdxDn3Tm15fYcYUtV0uf3hBmUGxYW-E",
    authDomain: "mumara-donate-fccf6.firebaseapp.com",
    databaseURL: "https://mumara-donate-fccf6.firebaseio.com",
    projectId: "mumara-donate-fccf6",
    storageBucket: "mumara-donate-fccf6.appspot.com",
    messagingSenderId: "285101667981",
    appId: "1:285101667981:web:e110f382a26e21904114c4",
    measurementId: "G-HFL7TPZLGL",

  };


  firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth;
export const db = firebase.firestore();
export const fireauth = firebase.auth()
