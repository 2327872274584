export async function sendPushNotification(token,title,body) {

 
    var parsed = JSON.stringify({
    to:`${token}`,
    notification : {
      body : body,
      title: title,
      sound:"default",

     
  }   
    });
  
  
    return fetch('https://fcm.googleapis.com/fcm/send', {
      headers: {
        'Content-Type': 'application/json',
       
        Authorization: 'key=AAAAQmFiNo0:APA91bEOqpez6G0j6RGcdL_Jxoga_0vhH6PhvOhaivOnNVSne_4213tE2QodtGXPpBavDpBBuB6aVUa-w-u273LhgB4ULgr_2UuuQMwg349SuFR8Alsjp9qzrXu2Ju8u2ZB9uSMC6HaD',
      },
      method: 'POST',
      body:parsed,
    })
      .then(r => r.json().then(data => ({status: r.status, body: data})))
  
      .catch(e => e);
  }