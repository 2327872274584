import React, { Component } from 'react';
import LoadingBar from 'react-top-loading-bar';
import {db} from '../services/firebase';
import {GeoFirestore} from 'geofirestore';
import "../styles/react_dates_overrides.css";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment'
import 'react-dates/initialize';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import {sendPushNotification } from '../utils/NetworkFunctions'
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Navbar,
    
    Nav,
    NavbarBrand,
    Card, CardText, CardBody,
   Button,
  
  
  } from 'reactstrap';
  

  const geofirestore = new GeoFirestore(db);
const geocollection = geofirestore.collection('posts');
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default class UnderReview extends React.Component {

constructor(props){
    super(props)

    this.state = {
        dropdownOpen:false,
        all_posts:[],
        poster_push_token:'',
        total_posts:0,
        date:'',
        focused:false,
        name:'',
        id:''
    }
}



componentDidMount(){

    this.fetchPosts();

}
timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
 var meridium = hour>12 ? 'PM' : 'AM'
    var time = date + ' ' + month + ' ' + year+'  '+hour+':'+min+ meridium  ;
    return time;
  }
    fetchPosts(){
    this.LoadingBar.continuousStart()

   geocollection
  .where('under_review','==',1)
  .get()
  .then( async snapshot => {
    var dataArray = [];
   await snapshot.docs.forEach(async item => {
      var post_id = item.id;
      dataArray.push({data: item.data(), id: post_id});
    });


  

    this.fetchPostUsers(dataArray)


   
  });
 }
 async fetchPostUsers(posts){
   if(posts.length>0){
  var promises = []



    posts.forEach( async (item,index)=>{

      var promise = db.collection('users').where('id','==',item.data.poster).get().then(async snapShot=>{
         item.user = snapShot.docs[0].data()

      }).catch(err=>{
        console.log(err)
      })
      
      promises.push(promise)
    })



  Promise.all(promises).then(async ()=>{
    this.setState({all_posts: posts});
    this.setState({total_posts:posts.length})
    this.LoadingBar.complete()
  }).catch(err=>{
    console.log(err)
  })

   }
  
  

   
 }
handleChange = (val)=> {
    var posts = [...this.state.all_posts]

   if(posts.length>0){
      if(val.target.value == 1){
       var sorted =  posts.sort((a, b) => (b.data.status) - (a.data.status));
       this.setState({all_posts:sorted})
      }

      else    if(val.target.value == 2){
       var sorted =  posts.sort((a, b) => (a.data.status) - (b.data.status));
       this.setState({all_posts:sorted})
      }

      else  if(val.target.value == 3){
       var sorted =  posts.sort((a, b) => (b.data.created_at) - (a.data.created_at));
       this.setState({all_posts:sorted})
      }



   }
   
 

}

toggle = ()=>{
    this.setState({dropdownOpen:!this.state.dropdownOpen})
}

changeStatus(id,mode,index){

    switch (mode) {
        case 0:
            this.changePostStatus(id)
            break;
    

            case 1:
            this.deletePost(id,index)
            break;


        default:
            break;
    }


     
 }


deletePost(id,index){

var r = window.confirm("Are you sure about this action?");
if (r == true) {
  var posts = [...this.state.all_posts]
  
db.collection("posts").doc(id).delete().then(()=> {
  posts.splice(index,1)
  this.setState({all_posts:posts})
  toast.success('Post deleted successfully!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    type:'success',
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
   
}).catch(function(error) {
    console.error("Error removing document: ", error);
});
}
 }

 changePostStatus(id,status,index){

      
    this.LoadingBar.continuousStart()
    geocollection
    .doc(id)
    .update({
      status: status == 0 ? 1 : 0,
    })
    .then(data => {
     
      this.LoadingBar.complete()

var posts = [...this.state.all_posts]
posts[index].data.status = status == 0 ? 1 : 0;
      this.setState({all_posts:posts})
    })
    .catch(err => {
        this.LoadingBar.complete()
  
    });
 }
 informUser(post,message){
  var promises = []
  let promise1 = db.collection('cloud_messaging_register').doc(post.poster).get()
  .then( snapShot => {
    if(snapShot.data() != undefined && snapShot.data != null){
      this.setState({poster_push_token : snapShot.data().token[0]})     
    }
  }).catch(err=>console.log(err))
    promises.push(promise1)
    Promise.all(promises).then(async ()=>{
        if(this.state.poster_push_token != ''){

          if(message!=undefined && message != ''){
            await sendPushNotification(this.state.poster_push_token,"Post Unapproved",message)

          }
          else{
            await sendPushNotification(this.state.poster_push_token,"Post Approved",`Your post in category ${post.category}, created at ${this.timeConverter(post.created_at)} has been approved.`)

          }
        }
    })
 }
 approvePost(post_id,index,post,mode){

  if(mode ==1){
   
      var posts = [...this.state.all_posts]
      
    db.collection("posts").doc(post_id).delete().then(async ()=> {
      posts.splice(index,1)
      this.setState({all_posts:posts})
     
      toast.success('Post has been disapproved!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        type:'success',
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });


        this.informUser(post,`Your post: ${post.description} has been unapproved.`)
       
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
    
  }
  else if(mode == 2){
    //approve post

    this.LoadingBar.continuousStart()
    geocollection
    .doc(post_id)
    .update({
      under_review: 0,
    })
    .then(data => {
      this.informUser(post)
      this.LoadingBar.complete()

var posts = [...this.state.all_posts]

   posts.splice(index,1)
      this.setState({all_posts:posts})
    })
    .catch(err => {
        this.LoadingBar.complete()
  
    });


  }

 }
 changePostType(id,item_type,index){

     this.LoadingBar.continuousStart()
     geocollection
     .doc(id)
     .update({
       type: item_type == 0 ? 1 : 0,
     })
     .then(data => {
      
       this.LoadingBar.complete()

var posts = [...this.state.all_posts]
posts[index].data.type = item_type == 0 ? 1 : 0;
       this.setState({all_posts:posts})
     })
     .catch(err => {
         this.LoadingBar.complete()
   
     });
 }
 showAlert(){
  toast.success('Post deleted successfully!', {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    type:'success',
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
 }
    render(){
      const start_date = moment().format('YYYY-MM-DD')
        var {name,all_posts,dropdownOpen,total_posts} = this.state
        return(   
           <div style={{backgroundColor:'white',height:'100%',boxSizing:'border-box'}}>

      <LoadingBar
          height={3}
          color='#1e824c'
          onRef={ref => (this.LoadingBar = ref)}
        />
        <ToastContainer />

      <Navbar color="light" light expand="md">
  
        <NavbarBrand><h3 style={{color:'#2e3131'}} >Posts Under Review</h3>    <Nav>
  
    </Nav></NavbarBrand>
        <Nav>
        <Dropdown  isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle color={'success'}  caret>
        Filter Posts
        </DropdownToggle>
      <DropdownMenu>
       
        <DropdownItem name="1" value="1" onClick={this.handleChange} >Active</DropdownItem>
    <DropdownItem name="2" value="2" onClick={this.handleChange} >Inactive</DropdownItem>
    <DropdownItem name="3" value="3" onClick={this.handleChange} >Created at</DropdownItem>

      </DropdownMenu>
    </Dropdown>

            </Nav>

 
         <Nav>

         
         {/* <SingleDatePicker
  date={this.state.date} // momentPropTypes.momentObj or null
  numberOfMonths={1}
  isDayHighlighted={day => day.isSame( start_date, 'day' )}
      style={{height:40}}

  isOutsideRange={() => false}
  onDateChange={date => this.setState({date})} // PropTypes.func.isRequired
  focused={this.state.focused} // PropTypes.bool
  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
  id="your_unique_id" // PropTypes.string.isRequired,
/> */}
         </Nav>

         <Nav className="ml-auto">
{total_posts>0 && <h4 style={{color:'#2e3131'}} >Total posts: {total_posts}</h4>}
         </Nav>
          {/* <NavbarText>Simple Text</NavbarText> */}
         
      </Navbar>
      
    
      <div style={{backgroundColor:'#fefefe',padding:20}}>
     
{all_posts.length > 0 && 
all_posts.map((item,index)=>{

return <Card style={{marginTop:10}}>
   
    <CardBody>
       
    <div>
    <h6 style={{color: item.data.status === 1?"#00b16a":"#cf000f",marginTop:10}}>
        {item.data.status === 0?'Inactive':'Active'}
    </h6>
       
        <h5 style={{color:"#013243"}}>{item.data.type == 0?'Donating':'Requesting'}{' '} {item.data.category=='Cash'?'amount '+item.data.amount:item.data.value} </h5>

    </div>
       
       
     <p style={{color:"#6c7a89"}}> {this.timeConverter(item.data.created_at)}    • in {item.data.category} - {item.data.sub_category}</p>
       
<h6 style={{color:"#2e3131",marginTop:10,fontWeight:'bold'}}>Description </h6>

{/* <h3>{item.user.first_name +' '+item.user.last_name}</h3> */}
<CardText>{item.data.description}</CardText> 
<br/>
<h6 style={{color:"#2e3131"}}>by {item.user.first_name+' '+item.user.last_name}</h6>




{item.data.type == 0  && <div>
    <h6 style={{color:"#2e3131",marginTop:10}}>Points</h6>

<CardText>{item.data.points != undefined ? item.data.points:0}</CardText>                     

    </div>}    

<h6 style={{color:"#2e3131",marginTop:10,fontWeight:'bold'}}>Address</h6>
<CardText>{item.data.address}</CardText>
 

     



  
<Button  color="primary" onClick={()=>this.changePostStatus(item.id,item.data.status,index)}>Change Status</Button>
<Button  color="danger" style={{marginLeft:10}}  onClick={()=>this.approvePost(item.id,index,item.data,1)}>Disapprove Post</Button>

    <Button  color="info" style={{marginLeft:30}} onClick={()=>this.approvePost(item.id,index,item.data,2)}>Approve Post</Button>




<Button  color="warning" style={{marginLeft:10}}  onClick={()=>this.changePostType(item.id,item.data.type,index)}>Change Type</Button>

    <Button outline color="danger" style={{marginLeft:10}} onClick={()=>this.changeStatus(item.id,1,index)} >Delete </Button>
    
    </CardBody>
  </Card>
})

}





</div>


</div>
        )
    }
}